<template>
  <div>
    <image-header src="@/assets/header_images/1.jpg" :text="$t('about.image-header')">
      <!-- 9, 10?, 11?, 12 -->
      <img style="object-position: center" src="@/assets/header_images/9.jpeg">
    </image-header>
    <content-sheet class="about-content">
      <div class="about-text">
        <h1 class="about-text-header text--primary text-left">{{ $t('about.sec1-title') }}</h1>
        <div class="about-text-grid text-left">
          <p><span class="text-bold">{{ $t('about.sec1-1-b') }}</span><br>{{$t('about.sec1-1')}}</p>
          <p><span class="text-bold">{{ $t('about.sec1-2-b') }}</span><br>{{$t('about.sec1-2')}}</p>
          <p><span class="text-bold">{{ $t('about.sec1-4-b') }}</span><br>{{$t('about.sec1-4')}}</p>
          <p><span class="text-bold">{{ $t('about.sec1-3-b') }}</span><br>{{$t('about.sec1-3')}}</p>
          <!-- <p><span class="text-bold">Plantera församlingar och starta skolor</span><br>Vi planterar församlingar bland olika stammar. Byggnaderna fungerar också som skolor för barnen.</p>
          <p><span class="text-bold">Välgörenhet</span><br>Vi vill gärna hjälpa till med mat och kläder. Vi köper också djur till familjer som blir till stor hjälp.Vi har även hjälpt människor som varit i behov av medicinsk vård.</p>
          <p><span class="text-bold">Kampanjer</span><br>Predika evangelium utomhus för större folksamlingar, ofta på större fält och på platser i närheten av städer.</p>
          <p><span class="text-bold">Konferenser</span><br>Uppmuntra, utmana och utrusta pastorer, ledare och lokala troende för deras framtida arbete.</p> -->
        </div>
      </div>
      <div class="about-hero">
        <img src="@/assets/about_hero.png">
      </div>
    </content-sheet>
    <content-sheet style="margin-top: 100px">
      <div class="about-team text-left">
        <div class="about-bio">
          <div>
            <img src="@/assets/Mats.png" alt="Mats Wennergrund">
          </div>
          <div>
            <h2 class="text--primary no-margin" style="margin-top: 20px">Mats Wennergrund</h2>
            <a href="mailto:mats@focusontheharvest.net"><p class="text-hoverable text--primary row-vertical-center"><MailIcon style="margin-right: 5px"/>mats@focusontheharvest.net</p></a>
            <p style="white-space: pre-wrap;">{{ $t('about.about-mats') }}</p>
            <!-- <p>Jag är en glad, givmild och omtänksam person som tycker om djur och natur. Jag tycker om att läsa böcker och en träningsrunda i skogen uppskattar jag väldigt mycket.</p>
            <p>Jag tycker om att resa och träffa nya människor från andra kulturer och länder. Jag har lätt för att tänka nytt och stort och jag vill vara till hjälp för många människor. Jag vill skriva historia.</p> -->
          </div>
        </div>
        <div class="about-bio about-bio-reversed">
          <div>
            <img src="@/assets/Mari.png" alt="Mari Wennergrund">
          </div>
          <div>
            <h2 class="text--primary no-margin" style="margin-top: 20px">Mari Wennergrund</h2>
            <!-- <p class="text-hoverable text--primary">mari@focusontheharvest.net</p> -->
            <a href="mailto:mari@focusontheharvest.net"><p class="text-hoverable text--primary row-vertical-center"><MailIcon style="margin-right: 5px"/>mari@focusontheharvest.net</p></a>
            <p style="white-space: pre-wrap;">{{ $t('about.about-mari') }}</p>
            <!-- <p>Jag har vandrat med Gud sedan jag var 4år och kan ge flera starka vittnesbörd om hur Gud varit nära mig i stora och små händelser i mitt liv. Min första missionsresa gjorde jag 2009 och det jag fick se och uppleva på plats förvandlade mitt liv. En eld tändes i mitt hjärta att nå de onådda med evangelium.</p>
            <p>Ett citat taget från boken Missionsbefallningen av Oswald J Smith har etsat sig fast i mitt hjärta: "Varför ska somliga höra evangelium två gånger innan alla har hört det en gång".</p>
            <p>Jag är mamma till två vuxna tvillingdöttrar och jag tycker om att möta människor från olika länder och kulturer.</p> -->
          </div>
        </div>
        <div class="about-bio">
          <div>
            <img src="@/assets/Gunnar.png" alt="Gunnar Svensson">
          </div>
          <div>
            <h2 class="text--primary no-margin" style="margin-top: 20px">Gunnar Svensson</h2>
            <a href="mailto:gunnar@focusontheharvest.net"><p class="text-hoverable text--primary row-vertical-center"><MailIcon style="margin-right: 5px"/>gunnar@focusontheharvest.net</p></a>
            <p style="white-space: pre-wrap;">{{ $t('about.about-gunnar') }}</p>
            <!-- <p>Jag är en person som har lätt för att skratta och ha roligt. Jag är intresserad av mode, musik och umgås med människor. Genom Guds nåd så har jag predikat sedan jag var 23 år. Jag har bland annat varit missionär i Tyskland i tio år. Där fick jag se många komma till Jesus och genom honom bli förvandlade och helade. Väl tillbaka till Sverige så fortsatte längtan att nå de som aldrig har hört evangeliet.</p>
            <p>För ca tio år sedan så gick jag igenom svåra saker men mitt i det sade jag fortfarande till mig själv – ”jag vill gå till de yttersta platserna på jorden med de goda nyheterna”. En predikant lade händerna på mig och och mötet med Herren var så otroligt starkt så det katapultade mig in i det som jag drömde om. Idag är jag tacksam om att få skriva historia tillsammans med den Helige Ande.</p> -->
          </div>
        </div>
      </div>
    </content-sheet>
  </div>
</template>

<script>
import ContentSheet from '../components/ContentSheet.vue'
import ImageHeader from '../components/ImageHeader.vue'
import MailIcon from '@/assets/icons/mail_fill_primary.svg'

export default {
  metaInfo() {
    return {
      title: this.$t('navbar.about')
    }
  },
  components: {
    ContentSheet,
    ImageHeader,
    MailIcon,
  },
  mounted () {
    window.scrollTo(0, 0)
  },
}
</script>

<style>

.about-content {
  display: flex;
}
.about-text {
  flex: 0 0 50%;
}
.about-text-header {
  padding-right: 5vw;
}
.about-hero {
  flex: 0 0 50%;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.about-hero > img {
  width: 90%;
}
.about-text-grid {
  display: grid;
  /* padding-right: 20px; */
  justify-content: space-between;
  grid-template-columns: 45% 45%;
  column-gap: 15px;
}

.about-team {
  display: flex;
  justify-content: space-between;
}

.about-bio {
  flex: 0 0 30%;
  min-width: 0;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.about-bio > div > img {
  display: block;
  margin: 0 auto;
  width: 80%;
}


@media screen and (max-width: 1150px) {
  .about-content {
    flex-direction: column-reverse;
  }
  .about-hero {
    justify-content: center;
  }
  .about-hero > img {
    width: 50%;
  }

  .about-team {
    flex-direction: column;
  }
  .about-bio {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
  .about-bio-reversed {
    flex-direction: row-reverse;
  }
  .about-bio > div {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 700px) {
  /* .about-content {
    flex-direction: column;
  } */
  .about-hero > img {
    width: 90%;
  }
  .about-text-header {
    padding-right: 0;
    text-align: center;
  }
  .about-text-grid {
    display: flex;
    flex-direction: column;
  }
  .about-text-grid p {
    padding-bottom: 20px;
  }

  .about-team {
    flex-direction: column;
  }
  .about-bio {
    display: block;
  }
  .about-bio-reversed {
    flex-direction: row-reverse;
  }
}

</style>
